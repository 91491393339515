import {
  HEATING_CARRIER_LEGEND,
  PRIORITY_AREA_LEGEND,
  SECTOR_LEGEND,
} from '../legendSettings';
import {
  heatingCarrierColors,
  hexToRgba,
  priorityAreaColors,
  sectorColors,
} from '../baseData/map-colors';
import { getAggregationSidebar, getLayerConfig, getStyle } from './helper';

export const LAYER_KEY__BLOCK = 'aggregation_buildingblock';
export const LAYER_KEY__DISTRICT = 'aggregation_district';
export const LAYER_KEY__MUNICIPALITY = 'aggregation_municipality';
export const LAYER_KEY__MUNICIPALITY_LINE = 'aggregation_municipality_line';
export const LAYER_KEY__CUSTOM_AGGREGATION = 'aggregation_custom';
export const LAYER_KEY__AGGREGATION_LEVEL_CLIENT_DATA = 'scaling_layer';

export const LAYER_KEY__COUNTY = 'aggregation_county';
export const LAYER_KEY__GOVERNMENT_DISTRICT = 'aggregation_government_district';
export const LAYER_KEY__FEDERAL_STATE = 'aggregation_federal_state';
export const LAYER_KEY__BKG_RASTER = 'aggregation_bkg_raster';
export const LAYER_KEY__HEAT_DENSITY_STREETS =
  'buildingmodel_heat_density_street_segment';

const FILL_COLOR = (key, type) => [
  'case',
  ['boolean', ['feature-state', 'clicked'], false],
  '#60bdff',
  ['boolean', ['feature-state', 'marked'], false],
  '#00E5FF',
  ['==', ['get', key], null],
  'rgba(255,255,255,0)',
  [
    'interpolate',
    ['linear'],
    ['get', key],
    type.min.value,
    type.min.color,
    type.max.value,
    type.max.color,
  ],
];

const AGGREGATION_STYLE = (name, interval) => ({
  style: {
    default: {
      fillColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#60bdff',
        ['boolean', ['feature-state', 'marked'], false],
        '#00E5FF',
        'rgba(255,255,255,0)',
      ],
      fillOpacity: 1,
      fillOutlineColor: '#000000',
    },
    sector: {
      fillColor: [
        'case',
        ['boolean', ['feature-state', 'clicked'], false],
        '#60bdff',
        ['boolean', ['feature-state', 'marked'], false],
        '#00E5FF',
        [
          'match',
          ['get', 'sector'],
          0,
          hexToRgba(sectorColors.COM),
          1,
          hexToRgba(sectorColors.PUB),
          2,
          hexToRgba(sectorColors.HH),
          4,
          hexToRgba(sectorColors.IND),
          hexToRgba(sectorColors.UNK),
        ],
      ],
      fillOpacity: 1,
      fillOutlineColor: '#000000',
    },
    heating_technology(property) {
      return {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          [
            'match',
            ['get', `heating_category_${property}`],
            'FOS',
            heatingCarrierColors.FOS,
            'REN',
            heatingCarrierColors.REN,
            'HNT',
            heatingCarrierColors.HNT,
            'ELE',
            heatingCarrierColors.ELE,
            'GRG',
            heatingCarrierColors.GRG,
            heatingCarrierColors.UKN,
          ],
        ],
        fillOpacity: 1,
      };
    },
    ...((name === 'Block' || name === 'Stadtteil') && {
      priority_area: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          [
            'match',
            ['get', 'priority_area'],
            'GAS',
            priorityAreaColors.dezentral,
            'ELE',
            priorityAreaColors.dezentral,
            'OIL',
            priorityAreaColors.dezentral,
            'DIS',
            priorityAreaColors.DIS,
            'PMP',
            priorityAreaColors.dezentral,
            'H2',
            priorityAreaColors.H2,
            'COA',
            priorityAreaColors.dezentral,
            'BIO',
            priorityAreaColors.dezentral,
            'SOL',
            priorityAreaColors.dezentral,
            priorityAreaColors.UKN,
          ],
        ],
        fillOpacity: 1,
      },
    }),
    ...(name === 'Block' && {
      construction_year: {
        fillColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#60bdff',
          ['boolean', ['feature-state', 'marked'], false],
          '#00E5FF',
          [
            'match',
            ['get', 'construction_year_class'],
            1,
            '#845EC2',
            2,
            '#2C73D2',
            3,
            '#0081CF',
            4,
            '#008E9B',
            '#404646',
          ],
        ],
        fillOpacity: 1,
      },
    }),
    emission(property) {
      const key = `co2_emissions_${property}`;
      return {
        fillColor: FILL_COLOR(key, interval.emission),
        fillOpacity: 1,
      };
    },
    heat_demand(property) {
      const key = `heat_demand_kwh_${property}`;
      return {
        fillColor: FILL_COLOR(key, interval.heatDemand),
        fillOpacity: 1,
      };
    },
    heat_density_line(property) {
      const key = `linear_heat_density_kwh_m_${property}`;
      return {
        fillColor: FILL_COLOR(key, interval.heatDensityLine),
        fillOpacity: 1,
      };
    },
    heat_demand_density(property) {
      const key = `heat_demand_density_mwh_ha_${property}`;
      return {
        fillColor: FILL_COLOR(key, interval.heatDensity),
        fillOpacity: 1,
      };
    },
    emission_saving(property) {
      const key = `co2_emissions_savings_${property}`;
      return {
        fillColor: FILL_COLOR(key, interval.emissionSaving),
        fillOpacity: 1,
      };
    },
  },
  legend: {
    sector: SECTOR_LEGEND(`${name} nach Sektoren`),
    heating_technology: HEATING_CARRIER_LEGEND(`Versorgungsart (${name})`),
    emission: {
      type: 'gradient',
      name: `${name} nach Emissionen`,
      unit: 't CO₂/a',
      items: interval.emission,
    },
    heat_demand: {
      type: 'gradient',
      name: `${name} nach Wärmebedarf`,
      unit: 'kWh',
      items: interval.heatDemand,
    },
    heat_density_line: {
      type: 'gradient',
      name: `${name} nach Wärmeliniendichte`,
      unit: 'kWh/m',
      items: interval.heatDensityLine,
    },
    heat_demand_density: {
      type: 'gradient',
      name: `${name} nach Wärmeverbrauchsdichte`,
      unit: 'MWh/ha',
      items: interval.heatDensity,
    },
    emission_saving: {
      type: 'gradient',
      name: `${name} nach Emissionseinsparung`,
      unit: 't CO₂/a',
      items: interval.emissionSaving,
    },
    construction_year: {
      name: `${name} nach Baualtersklasse`,
      unit: '',
      items: [
        {
          color: '#845EC2',
          name: 'vor 1949',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: '#2C73D2',
          name: '1949-1968',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: '#0081CF',
          name: '1969-2001',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: '#008E9B',
          name: 'nach 2001',
          icon: 'mdi-checkbox-blank-circle',
        },
        {
          color: '#404646',
          name: 'Unbekannt',
          icon: 'mdi-checkbox-blank-circle',
        },
      ],
    },
    priority_area: PRIORITY_AREA_LEGEND(`${name} nach Vorranggebiet`),
  },
});

export default {
  [LAYER_KEY__BLOCK]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__BLOCK, {
      routerName: 'buildingBlock',
    }),
    sidebar: getAggregationSidebar('Block', [
      'district_name',
      'municipality_name',
    ]),
    ...AGGREGATION_STYLE('Block', getStyle(400, 2000000, 6000, 600, 300)),
  },
  [LAYER_KEY__DISTRICT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__DISTRICT),
    sidebar: getAggregationSidebar('Stadtteil', [
      'district_name',
      'municipality_name',
    ]),
    ...AGGREGATION_STYLE(
      'Stadtteil',
      getStyle(20000, 60000000, 6000, 30, 8000),
    ),
  },
  [LAYER_KEY__MUNICIPALITY]: {
    layoutState: 'default',
    visible: true,
    layerConfig: getLayerConfig(LAYER_KEY__MUNICIPALITY, {
      visible: 'visible',
    }),
    sidebar: getAggregationSidebar('Gemeinde', ['municipality_name']),
    ...AGGREGATION_STYLE(
      'Gemeinde',
      getStyle(200000, 600000000, 6000, 40, 80000),
    ),
  },
  [LAYER_KEY__MUNICIPALITY_LINE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      id: LAYER_KEY__MUNICIPALITY,
      sourceLayer: LAYER_KEY__MUNICIPALITY,
      type: 'line',
      applyYearlyFilter: false,
      layerId: LAYER_KEY__MUNICIPALITY_LINE,
      visibility: 'none',
      dependsOnScenario: true,
      applyMunicipalityFilter: true,
    },
    sidebar: {},
    style: {
      default: {
        lineColor: 'lightgray',
        lineWidth: 3,
      },
    },
  },
  [LAYER_KEY__CUSTOM_AGGREGATION]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__CUSTOM_AGGREGATION, {
      routerName: 'customAggregation',
    }),
    sidebar: getAggregationSidebar('Benutzerdefinierte Fläche', []),
    ...AGGREGATION_STYLE(
      'Benutzerdefinierte Fläche',
      getStyle(30000, 90000000, 6000, 1000, 30000),
    ),
  },
  [LAYER_KEY__FEDERAL_STATE]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__FEDERAL_STATE),
    sidebar: getAggregationSidebar('Bundesland', ['name']),
    ...AGGREGATION_STYLE(
      'Bundesland',
      getStyle(9000000, 50000000000, 6000, 20, 6000000),
    ),
  },
  [LAYER_KEY__GOVERNMENT_DISTRICT]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__GOVERNMENT_DISTRICT),
    sidebar: getAggregationSidebar('Regierungsbezirk', ['name']),
    ...AGGREGATION_STYLE(
      'Regierungsbezirk',
      getStyle(9000000, 50000000000, 6000, 20, 6000000),
    ),
  },
  [LAYER_KEY__COUNTY]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__COUNTY),
    sidebar: getAggregationSidebar('Landkreis', ['name']),
    ...AGGREGATION_STYLE(
      'Landkreis',
      getStyle(800000, 4000000000, 6000, 20, 600000),
    ),
  },
  [LAYER_KEY__BKG_RASTER]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__BKG_RASTER),
    sidebar: getAggregationSidebar('BKG-Raster', ['id']),
    ...AGGREGATION_STYLE('BKG-Raster', getStyle(200, 600000, 6000, 600, 90)),
  },
  [LAYER_KEY__HEAT_DENSITY_STREETS]: {
    layoutState: 'default',
    visible: false,
    layerConfig: {
      key_name: 'id',
      type: 'line',
      id: LAYER_KEY__HEAT_DENSITY_STREETS,
      sourceLayer: LAYER_KEY__HEAT_DENSITY_STREETS,
      layerId: LAYER_KEY__HEAT_DENSITY_STREETS,
      visibility: 'none',
      dependsOnScenario: true,
      applyYearlyFilter: false,
      applyMunicipalityFilter: true,
    },
    sidebar: {
      name: 'Straßenabschnitt',
      subtitles: [{ key: 'id' }],
      chips: [
        {
          title: 'Straßenabschnitt',
          active: true,
          items: [
            {
              title: 'Auswertung',
              active: true,
              items: [
                {
                  key: 'heat_demand_kwh',
                  unit: 'kWh',
                  decimal: 0,
                },
                {
                  key: 'linear_heat_density_kwh_m',
                  unit: 'kWh/m',
                  decimal: 0,
                },
                {
                  key: 'segment_length_m',
                  unit: 'm',
                  decimal: 0,
                },
                {
                  key: 'no_of_buildings_with_heat_demand',
                  unit: '',
                  decimal: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    style: {
      default(year) {
        return {
          lineColor: [
            'case',
            ['boolean', ['feature-state', 'clicked'], false],
            '#60bdff',
            [
              'case',
              ['==', ['get', `linear_heat_density_kwh_m_${year}`], 0],
              '#9E9E9E',
              [
                'step',
                ['get', `linear_heat_density_kwh_m_${year}`],
                '#76FF03',
                501,
                '#00E676',
                1001,
                '#FFEA00',
                1501,
                '#FF9100',
                2001,
                '#F44336',
                2501,
                '#B71C1C',
                3000,
                '#880E4F',
              ],
            ],
          ],
          lineWidth: [
            'interpolate',
            ['exponential', 0.5],
            ['zoom'],
            15,
            1.5,
            18,
            2.5,
          ],
        };
      },
      heat_demand(year) {
        return {
          lineColor: [
            'case',
            ['boolean', ['feature-state', 'clicked'], false],
            '#60bdff',
            [
              'interpolate',
              ['linear'],
              ['get', `heat_demand_kwh_${year}`],
              0,
              'rgb(254,232,200)',
              700000,
              'rgb(227,74,51)',
            ],
          ],
          lineWidth: [
            'interpolate',
            ['exponential', 0.5],
            ['zoom'],
            15,
            1.5,
            18,
            2.5,
          ],
        };
      },
    },
    legend: {
      default: {
        name: 'Wärmeliniendichte in kWh/m',
        unit: '',
        items: [
          {
            color: '#9E9E9E',
            name: '0',
            icon: 'mdi-minus',
          },
          {
            color: '#76FF03',
            name: '1 - 500',
            icon: 'mdi-minus',
          },
          {
            color: '#00E676',
            name: '501 - 1000',
            icon: 'mdi-minus',
          },
          {
            color: '#FFEA00',
            name: '1001 - 1500',
            icon: 'mdi-minus',
          },
          {
            color: '#FF9100',
            name: '1501 - 2000',
            icon: 'mdi-minus',
          },
          {
            color: '#F44336',
            name: '2001 - 2500',
            icon: 'mdi-minus',
          },
          {
            color: '#B71C1C',
            name: '2501 - 3000',
            icon: 'mdi-minus',
          },
          {
            color: '#880E4F',
            name: 'größer 3000',
            icon: 'mdi-minus',
          },
        ],
      },
      heat_demand: {
        type: 'gradient',
        name: 'Wärmebedarf',
        unit: 'kWh',
        items: {
          min: {
            value: 0,
            color: 'rgb(254,232,200)',
          },
          max: {
            value: '700.000',
            color: 'rgb(227,74,51)',
          },
        },
      },
    },
  },
  [LAYER_KEY__AGGREGATION_LEVEL_CLIENT_DATA]: {
    layoutState: 'default',
    visible: false,
    layerConfig: getLayerConfig(LAYER_KEY__AGGREGATION_LEVEL_CLIENT_DATA, {
      routerName: 'scalingLayer',
    }),
    ...AGGREGATION_STYLE(
      'Aggregationsebene Kundendaten',
      getStyle(30000, 90000000, 6000, 1000, 30000),
    ),
  },
};
