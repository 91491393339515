<template>
  <div v-if="route.query.category" class="flex gap-5 pt-1">
    <div class="flex flex-wrap gap-10">
      <div class="flex flex-row gap-8 w-100">
        <div class="flex basis-1/2">
          <div class="p-4 w-100 rounded-[6px] standard-elevation-1">
            <div class="flex pb-6 w-100">
              <template v-for="(taskCategory, i) in tasks" :key="i">
                <ButtonEl
                  :class="{
                    'rounded-r-none': i < tasks.length - 1,
                    'rounded-l-none': i > 0,
                  }"
                  :variant="
                    taskCategory.categoryName === route.query.category
                      ? 'primary'
                      : 'secondary'
                  "
                  color="color2"
                  size="xl"
                  :text="taskCategory.categoryName"
                  @click="
                    router.push({
                      query: { category: taskCategory.categoryName },
                    })
                  "
                />
              </template>
            </div>
            <VExpansionPanels
              v-model="activePanel"
              :mandatory="true"
              variant="accordion"
              class="gap-1 task-expansion-panel h-fit"
            >
              <VExpansionPanel
                v-for="(item, index) in curTaskCategory.taskGroups"
                :key="index"
              >
                <VExpansionPanelTitle>
                  <h5>{{ item.title }}</h5>
                </VExpansionPanelTitle>
                <VExpansionPanelText>
                  <div class="flex flex-col gap-1">
                    <TaskSection
                      :tasks="item.tasks"
                      :active-button-text="activeFormTask.buttonText"
                      @task-click="handleTaskClick($event)"
                    />
                  </div>
                </VExpansionPanelText>
              </VExpansionPanel>
            </VExpansionPanels>
          </div>
        </div>
        <div class="flex basis-1/2">
          <div class="p-4 w-100 rounded-[6px] standard-elevation-1">
            <h3
              v-if="curTaskCategory.taskGroups[activePanel].withInput"
              class="text-title-color2 mb-3"
            >
              TASK: {{ activeFormTask?.buttonText.toUpperCase() }}
            </h3>
            <h3 v-else class="text-title-color2 mb-3">
              {{ curTaskCategory.taskGroups[activePanel].title.toUpperCase() }}
            </h3>
            <div
              v-if="curTaskCategory.taskGroups[activePanel].withInput"
              class="flex flex-col gap-2 rounded pt-4"
            >
              <div class="whitespace-pre-line mb-3 flex flex-col gap-5 px-2">
                <div
                  v-for="item in activeFormTask.helperText"
                  :key="item.text"
                  class="flex"
                >
                  <IconWrapper :icon="item.icon" type="outlined" :size="16" />
                  <p class="pl-1 body-3">{{ item.text }}</p>
                </div>
              </div>
              <div v-if="activeFormTask.loading">
                <skeleton-loader-list
                  :amount-rows="activeFormTask.fields.length"
                />
              </div>
              <div
                v-for="(field, index) in activeFormTask.fields"
                v-else
                :key="index"
              >
                <TheLabel
                  v-if="field.type === 'file'"
                  :label="field.label"
                  :for-input="field.name"
                />
                <UploadField
                  v-if="field.type === 'file'"
                  :id="field.name"
                  v-model="field.value"
                  :name="field.name"
                  :label="`${field.label} als ${field.fileExtension === 'csv' ? 'CSV' : 'GeoJSON'}`"
                  label-color="text-text-neutral"
                  background-color="bg-active-area"
                  :rules="{
                    fileExtension: `${field.fileExtension === 'csv' ? 'csv' : 'geojson'}`,
                  }"
                />
                <TextAreaEl
                  v-if="field.type === 'textarea'"
                  :id="field.name"
                  v-model="field.value"
                  background-color="bg-active-area"
                  :label="field.label"
                  :placeholder="field.placeholder"
                />
                <InputEl
                  v-if="field.type === 'integer'"
                  :id="field.name"
                  v-model="field.value"
                  :label="field.label"
                  input-type="integer"
                  :rules="{ required: true }"
                  :apply-thousands-separator="false"
                />
                <SwitchItem
                  v-if="field.type === 'switch'"
                  v-model="field.value"
                  :label="field.label"
                />
                <DropDown
                  v-if="field.type === 'select'"
                  v-model="field.value"
                  :label="field.label"
                  :items-data="field.itemsData"
                  items-data-key="name"
                  value="value"
                />
                <DropDownMultiple
                  v-if="field.type === 'select-multiple'"
                  v-model="field.value"
                  :label="field.label"
                  :items="field.itemsData"
                  :item-type-all="field.itemTypeAll"
                  data-key="name"
                  value-key="value"
                />
              </div>
              <ButtonEl
                class="mt-2"
                name="create-task"
                text="Task anlegen"
                :disabled="activeFormTask.loading"
                @click="runFormTask"
              />
            </div>
            <p v-else class="body-1">
              Wählen Sie links einen Task aus um diesen zu starten.
            </p>
          </div>
        </div>
      </div>
      <div class="flex basis-full">
        <div class="p-4 w-100 rounded-[6px] standard-elevation-1">
          <h3 class="text-title-color2 mb-3">TASKÜBERSICHT</h3>
          <VDataTableServer
            :items="tableData"
            :headers="curTaskCategory.resultTableHeaders"
            :items-length="totalNumberOfTableItems"
            :loading="loading"
            :hover="true"
            item-key="id"
            class="h-fit"
            @update:options="updateTableData"
            @click:row="rowClick"
          >
            <template #[`item.downloadable_file.file_url`]="{ item }">
              <a
                v-if="
                  item.downloadable_file &&
                  item.downloadable_file.file_url &&
                  !item.downloadable_file.deleted
                "
                :href="item.downloadable_file.file_url"
                target="_blank"
                @click.stop
              >
                <IconWrapper
                  icon="download"
                  hover="hover:text-success"
                  class="cursor-pointer"
                />
                <VTooltip activator="parent" location="start"
                  >Datei herunterladen
                </VTooltip>
              </a>
              <div
                v-if="
                  item.downloadable_file &&
                  item.downloadable_file.file_url &&
                  item.downloadable_file.deleted
                "
                @click.stop
              >
                <IconWrapper
                  icon="file_download_off"
                  fill="text-disabled-color2"
                />
                <VTooltip activator="parent" location="start"
                  >Datei gelöscht
                </VTooltip>
              </div>
            </template>
          </VDataTableServer>
        </div>
      </div>
    </div>
  </div>
  <VuetifyDialog
    v-if="activeSimpleTask"
    v-model="isDialogOpen"
    title="Möchten sie diese Aktion wirklich durchführen?"
  >
    <template #content>
      Dieser Vorgang kann nicht rückgängig gemacht werden.
    </template>
    <template #actions>
      <ButtonEl variant="secondary" text="Abbrechen" @click="abort" />
      <ButtonEl
        :text="
          activeSimpleTask.confirmButtonText ?? activeSimpleTask.buttonText
        "
        @click="runSimpleTask(activeSimpleTask.url)"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { axios } from '@/utils/axiosHelper';
import { useRoute, useRouter } from 'vue-router';
import cookie from 'vue-cookies';
import { tasks } from '@/apps/task-manager/tasks';
import { formatISODateAndTime } from '@/utils/formatUtils';
import { useStore } from 'vuex';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import TheLabel from '@/components/storybook/src/stories/label/TheLabel.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import SkeletonLoaderList from '@/components/skeleton-loader/skeletonLoaderList.vue';
import TaskSection from '@/apps/task-manager/TaskSection.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import UploadField from '@/components/storybook/src/stories/uploadField/UploadField.vue';
import TextAreaEl from '@/components/storybook/src/stories/textAreaEl/TextAreaEl.vue';
import SwitchItem from '@/components/storybook/src/stories/SwitchItem/SwitchItem.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import DropDownMultiple from '@/components/storybook/src/stories/dropDownMultiple/DropDownMultiple.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';

const router = useRouter();
const route = useRoute();
const store = useStore();

const loading = ref(true);
const tableOptions = ref({
  itemsPerPage: 10,
  page: 1,
});
const totalNumberOfTableItems = ref(0);
const tableData = ref([]);
const activePanel = ref(0);
const activeSimpleTask = ref(null);
const activeFormTask = ref(tasks[0].taskGroups[0].tasks[0]);
const isDialogOpen = ref(false);

const curTaskCategory = computed(() => {
  return tasks.find((t) => t.categoryName === route.query.category);
});

function handleTaskClick(task) {
  if (curTaskCategory.value.taskGroups[activePanel.value].withInput) {
    activeFormTask.value = task;
  } else {
    activeSimpleTask.value = task;
    isDialogOpen.value = true;
  }
}

watch(
  () => route.query.category,
  () => {
    const firstTaskGroupInCategory = curTaskCategory.value.taskGroups[0];
    activePanel.value = 0;
    if (firstTaskGroupInCategory.withInput) {
      activeFormTask.value = firstTaskGroupInCategory.tasks[0];
    }
    getTableData();
  },
);

watch(
  () => activePanel.value,
  () => {
    const activeTaskGroup = curTaskCategory.value.taskGroups[activePanel.value];
    if (activeTaskGroup.withInput) {
      activeFormTask.value = activeTaskGroup.tasks[0];
    }
  },
);

watch(
  () => activeFormTask.value,
  () => {
    if (activeFormTask.value && activeFormTask.value.valueGetterActions) {
      activeFormTask.value.loading = true;
      Promise.all(
        activeFormTask.value.valueGetterActions.map((action) => {
          return store.dispatch(action);
        }),
      ).then(() => {
        for (let field of activeFormTask.value.fields) {
          if (field.itemsDataGetter) {
            field.itemsData = store.getters[field.itemsDataGetter];
            console.log(field.itemsData);
          }
          if (field.itemFormatter) {
            field.itemsData = field.itemFormatter(field.itemsData);
          }
          if (field.initialValueFunction) {
            field.value = field.initialValueFunction(field.itemsData);
          }
        }
        activeFormTask.value.loading = false;
      });
    }
  },
);

function runFormTask() {
  const formData = new FormData();

  activeFormTask.value.fields?.forEach((field) => {
    formData.append(field.name, field.value);
  });

  const csrfToken = cookie.get('csrftoken');
  formData.append('csrfmiddlewaretoken', csrfToken);

  axios({
    url: activeFormTask.value.url,
    method: 'POST',
    data: formData,
  })
    .then(() => getTableData())
    .catch((err) => {
      return err;
    });
}

function runSimpleTask(url) {
  axios({
    url: url,
    method: 'POST',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => getTableData());
  isDialogOpen.value = false;
}

function abort() {
  isDialogOpen.value = false;
  activeSimpleTask.value = null;
}

function rowClick(click, row) {
  store.commit('building/TOGGLE_SHOW_TASK_DETAILS', row.item.id);
}

function updateTableData(options) {
  loading.value = true;
  tableOptions.value = options;
  getTableData();
}

let intervalId;
onMounted(() => {
  intervalId = setInterval(() => {
    getTableData();
  }, 2_000);
});

onUnmounted(() => clearInterval(intervalId));

function getTableData() {
  return axios({
    url: curTaskCategory.value.resultUrl,
    method: 'GET',
    params: {
      page: tableOptions.value.page,
      'page-size': tableOptions.value.itemsPerPage,
    },
    skipLoading: true,
  }).then((response) => {
    totalNumberOfTableItems.value = response.data.count;
    tableData.value = response.data.results.map((task) => {
      task.created_at = formatISODateAndTime(task.created_at);
      return task;
    });
    loading.value = false;
  });
}
</script>

<style scoped lang="scss">
.task-expansion-panel {
  :deep(.v-expansion-panel__shadow) {
    @apply shadow-none;
  }

  :deep(.v-expansion-panel::after) {
    @apply border-none;
  }

  :deep(.v-expansion-panel-title) {
    width: 100% !important;
    padding: 13px 17px 13px 10px;
    border-radius: 4px;
    @apply bg-subtle;
    h5 {
      @apply text-title-neutral;
    }

    :deep(&.v-expansion-panel-title--active) {
      border-radius: 4px 4px 0 0;
      min-height: 40px !important;
    }
  }

  .v-expansion-panel-title__overlay {
    opacity: 0;
  }

  :deep(.v-expansion-panel-text) {
    border-radius: 0 0 4px 4px;
    @apply bg-active-area;
    :deep(.v-expansion-panel-text__wrapper) {
      padding: 20px 10px;
    }
  }
}
</style>
