<template>
  <div class="flex items-center body-3 pt-2">
    <div class="w-fit">
      {{ displayMinValue }}
    </div>
    <div
      class="w-full text-center mx-1 text-xs"
      :style="`background: linear-gradient(to right, ${minColor}, ${maxColor})`"
    >
      {{ unit }}
    </div>
    <div class="whitespace-nowrap">
      {{ displayMaxValue }}
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  unit: {
    type: String,
    required: true,
  },
  minValue: {
    type: [Number, String],
    required: true,
  },
  maxValue: {
    type: [Number, String],
    required: true,
  },
  minColor: {
    type: String,
    required: true,
  },
  maxColor: {
    type: String,
    required: true,
  },
});

const displayMaxValue = computed(() => formatValue(props.maxValue));

const displayMinValue = computed(() => formatValue(props.minValue));

function formatValue(value) {
  return typeof value === 'string' ? value : value.toLocaleString('de-DE');
}
</script>
