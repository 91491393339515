<template>
  <div class="flex flex-col gap-5">
    <TextBox size="m">
      Hier haben Sie die Möglichkeit, manuelle Updates an dem Gebäudemodell
      zurückzusetzen. Markieren Sie dafür zunächst die Gebäude in der
      untenstehenden Tabelle und wählen Sie anschließend die entsprechenden
      Informationen, die Sie zurücksetzen möchten aus. Bitte beachten Sie, dass
      das Zurücksetzen manueller Updates unwiderruflich ist.
    </TextBox>

    <!-- filter-->
    <div class="flex gap-4 justify-between">
      <div class="flex gap-2 items-end">
        <DropDown
          v-model="currentFilter"
          class="min-w-[150px]"
          :items-data="filterBy"
          items-data-key="name"
          label="Filter nach"
        />
        <InputEl
          v-show="currentFilter === 'street'"
          v-model="street"
          class="min-w-[275px]"
          :placeholder="`Bitte Straße eingeben`"
          suffix="ZoomIcon"
          :suffix-icon="true"
        />
        <InputEl
          v-show="currentFilter === 'alkis_id'"
          v-model="alkisId"
          class="min-w-[275px]"
          :placeholder="`Bitte Alkis-ID eingeben`"
          suffix="ZoomIcon"
          :suffix-icon="true"
        />
      </div>

      <div class="flex flex-col justify-start">
        <TheLabel label="Filter letzte Änderung" />
        <div class="flex items-center gap-2">
          <DatePicker
            v-model="startDate"
            :clearable="true"
            class="w-[200px]"
            placeholder="von"
          />
          <DatePicker
            v-model="endDate"
            :clearable="true"
            class="w-[200px]"
            placeholder="bis"
          />
          <ButtonEl
            icon="filter_alt"
            :icon-right="false"
            icon-type="filled"
            text="Filter setzen"
            @click="
              loadBuildings({
                page: currentPage,
                itemsPerPage: currentItemsPerPage,
              })
            "
          />
        </div>
      </div>
    </div>

    <VuetifyDataTable
      :headers="headers"
      :item-data="buildingsData.data"
      item-value="aggregate"
      :show-select="true"
      :total-items="buildingsData.count"
      :open-page-options-to-top="true"
      :show-expand="false"
      class="gap-2 standard-elevation-1 rounded-[8px] rounded-t-none h-full"
      data-cy="building-versioning-table"
      @update:options="loadBuildings"
      @selected-rows="selectedRows = $event"
    >
      <template #top>
        <div
          class="w-fit pt-2"
          :class="{ 'pointer-events-none': selectedRows.length === 0 }"
        >
          <ResetDataModalVersioning
            class="px-[18px] pt-3 pb-1"
            :writable-fields="writableFields"
            @update-buildings="updateBuildings($event)"
          >
            <ButtonEl
              text="Daten bearbeiten"
              icon="edit_note"
              :disabled="selectedRows.length === 0"
              :icon-right="false"
            />
          </ResetDataModalVersioning>
        </div>
      </template>

      <template #show_details="item">
        <VTooltip>
          <template #activator="{ props }">
            <ButtonEl
              text="Details"
              size="m"
              variant="secondary"
              color="success"
              v-bind="props"
            />
          </template>
          <template v-if="isLoaded">
            <div
              v-for="subItem in getItemWritableFields(item.item)"
              :key="subItem"
              class="flex"
            >
              <VIcon class="-ml-3" icon="mdi-circle-medium" color="red" />
              <div>{{ subItem }}</div>
            </div>
          </template>
        </VTooltip>
      </template>

      <template #updated_at="item">
        <div class="flex justify-end">
          {{ getUpdatedColumnText(item.item) }}
        </div>
      </template>
    </VuetifyDataTable>
  </div>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, onMounted, ref } from 'vue';
import cookie from 'vue-cookies';
import TextBox from '@/components/storybook/src/stories/textBox/TextBox.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import TheLabel from '@/components/storybook/src/stories/label/TheLabel.vue';
import DatePicker from '@/components/storybook/src/stories/datePicker/DatePicker.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import ResetDataModalVersioning from '@/features/building-versioning/ResetDataModalVersioning.vue';

const isLoaded = ref(false);
const selectedRows = ref([]);

const headers = [
  {
    title: '',
    key: 'show_details',
    align: 'center',
    sortable: false,
    width: '100px',
  },
  {
    title: 'ALKIS-ID',
    key: 'aggregate',
    width: '250px',
    align: 'left',
  },
  {
    title: 'Straße',
    key: 'street',
    align: 'left',
    width: '250px',
  },
  {
    title: 'Nr.',
    key: 'house_number',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Letzte Änderung',
    key: 'updated_at',
    align: 'end',
    width: '200px',
  },
];

const currentFilter = ref('street');
const filterBy = [
  { name: 'Straße', value: 'street' },
  { name: 'Alkis ID', value: 'alkis_id' },
];

onMounted(async () => {
  try {
    await loadBuildings({ page: 1, itemsPerPage: 10 });
    await getOptionsBuildings();
    isLoaded.value = true;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
});

// filter params
const startDate = ref();
const endDate = ref();
const street = ref();
const alkisId = ref();
const currentPage = ref();
const currentItemsPerPage = ref();
// data
const buildingPostOptions = ref({});
const buildingsData = ref([]);

const stripTime = (date) => {
  if (!date) return null;
  const d = new Date(date);
  return d.toISOString().split('T')[0];
};
const writableFields = computed(() => {
  const writableFields = [];
  for (const key in buildingPostOptions.value) {
    if (buildingsData.value.writable_fields.includes(key)) {
      writableFields.push({
        key: key,
        label: buildingPostOptions.value[key].label,
      });
    }
  }
  return writableFields;
});

function loadBuildings(data) {
  currentPage.value = data.page;
  currentItemsPerPage.value = data.itemsPerPage;
  const payload = {
    street: street.value || null,
    alkis_id: alkisId.value || null,
    page: currentPage.value,
    page_size: currentItemsPerPage.value,
    sortBy: data.sortBy?.[0]?.key,
    sortOrder: data.sortBy?.[0]?.order,
  };
  if (startDate.value) {
    payload.updated_start = stripTime(startDate.value);
  }
  if (endDate.value) {
    payload.updated_end = stripTime(endDate.value);
  }
  return axios({
    url: '/api/buildingmodel/version-control/',
    params: payload,
    method: 'GET',
  }).then((response) => {
    buildingsData.value = response.data;
  });
}

function getOptionsBuildings() {
  return axios({
    url: '/api/buildingmodel/buildings/',
    method: 'OPTIONS',
  }).then((response) => {
    buildingPostOptions.value = response.data.actions.POST;
  });
}

function updateBuildings(payload) {
  axios({
    url: '/api/buildingmodel/version-control/',
    method: 'PUT',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: {
      alkis_ids: selectedRows.value,
      columns: payload.fields,
      use_heat_demand_estimation: payload.useHeatDemandEstimation,
    },
  }).then(() => {
    loadBuildings({
      page: currentPage.value,
      page_size: currentItemsPerPage.value,
    });
  });
}

function getItemWritableFields(item) {
  const writableFields = [];
  for (const fieldName of item.custom_fields) {
    writableFields.push(buildingPostOptions.value[fieldName].label);
  }
  return writableFields;
}

function getUpdatedColumnText(item) {
  let text = new Date(item.updated_at).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  if (item.updated_by) text += ` von ${item.updated_by}`;
  return text;
}
</script>
