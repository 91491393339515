export const subtitles = [
  { key: 'unit_id' },
  { key: 'plant_operator_id' },
  { key: 'eeg_id' },
  { key: 'name' },
];
export const betriebsinformationen = {
  title: 'Betriebsinformationen',
  active: false,
  items: [
    {
      key: 'unit_state',
      unit: '',
      decimal: 2,
      class: 'powerplant',
    },
    {
      key: 'capacity_kw',
      unit: 'kW',
      decimal: 2,
      class: 'powerplant',
    },
    {
      key: 'start_up_date',
      unit: '',
      decimal: 2,
      class: 'powerplant',
    },
    {
      key: 'planned_start_up_date',
      unit: '',
      decimal: 2,
      class: 'powerplant',
    },
    {
      key: 'type_of_feed_in',
      unit: '',
      decimal: 2,
      class: 'powerplant',
    },
  ],
};

export const clickedColor = '#850000';

export const defaultColors = {
  wind: '#7886CB',
  solar: '#FFF276',
  bio: '#9E9D24',
  geo: '#00579B',
};
export const layerValueRanges = {
  wind: {
    min: 30,
    max: 7000,
    startColor: '#c2c7e8', // Light blue
    endColor: '#4253b5', // Dark blue
    noValue: '#bdbdbb',
  },
  bio: {
    min: 30,
    max: 800,
    startColor: '#edf1bf', // Light green
    endColor: '#847a1c', // Dark green
    noValue: '#bdbdbb',
  },
  solar: {
    min: 30,
    max: 10000,
    startColor: '#fff7c0', // Light yellow
    endColor: '#fbc12e', // Dark yellow
    noValue: '#bdbdbb',
  },
  geo: {
    min: 30,
    max: 10000,
    startColor: '#ffdead', // Light orange
    endColor: '#fb8d01', // Dark orange
    noValue: '#bdbdbb',
  },
  kwk: {
    min: 0,
    max: 1000,
    startColor: '#edf1bf',
    endColor: '#847a1c',
    noValue: '#bdbdbb',
  },
  default: {
    min: 0,
    max: 1,
    startColor: '#000000', // Default black
    endColor: '#000000', // Default black
  },
};
