const fieldsAnschlussGeneral = [
  {
    id: 'network_length_expansion_m',
    disabled: true,
    label: 'Benötigte Trassenlänge',
    suffix: 'm',
    inputType: 'float',
    rules: { required: true },
    decimals: 2,
  },
  {
    id: 'start_at_year',
    label: 'Geplantes Startjahr',
    inputType: 'integer',
    rules: { range: [2023, 2050] },
    applyThousandsSeparator: false,
  },
  {
    id: 'district_heating_connection_rate_per_year',
    label: 'Fernwärme Erschließungsgrad',
    rules: { required: true, range: [0, 1, 'percent'] },
    inputType: 'percent',
    suffix: '%',
  },
  {
    id: 'network_losses_percentage',
    label: 'Netzverluste',
    inputType: 'float',
    rules: { required: true },
    suffix: 'MWh/m',
  },
];

const fieldsAnschlussInvest = [
  {
    id: 'specific_invest_amount',
    label: 'Benötigter Sonderinvest',
    inputType: 'float',
    rules: { required: true },
    suffix: '€',
  },
  {
    id: 'interest_rate',
    label: 'Kapital-Zinssatz (WAK, vor Steuern)',
    inputType: 'percent',
    rules: { required: true },
    suffix: '%',
  },
  {
    id: 'funding_rate_percentage',
    label: 'Fördersatz BEW',
    inputType: 'percent',
    suffix: '%',
  },
  {
    id: 'opex_costs_euro_mwh',
    label: 'Opex Pauschale',
    inputType: 'float',
    rules: { required: true },
    suffix: '€/MWh',
  },
  {
    id: 'opex_share_capex',
    label: 'Anteil an CAPEX p.a.',
    inputType: 'percent',
    rules: { required: true },
    suffix: '%',
  },
];

const fieldsAnschlussSensitivity = [
  {
    id: 'lower_sensitivity_heatdemand',
    label: 'Sensitivität - niedrigerer Absatz',
    inputType: 'percent',
    rules: { required: true, range: [-1, 0, 'percent'] },
    suffix: '%',
  },
  {
    id: 'upper_sensitivity_heatdemand',
    label: 'Sensitivität - höherer Absatz',
    inputType: 'percent',
    rules: { required: true, range: [0, 1, 'percent'] },
    suffix: '%',
  },
  {
    id: 'lower_sensitivity_network',
    label: 'Sensitivität - niedrigere Transportleitungskosten',
    inputType: 'percent',
    rules: { required: true, range: [-1, 0, 'percent'] },
    suffix: '%',
  },
  {
    id: 'upper_sensitivity_upper_network',
    label: 'Sensitivität - höhere Transportleitungskosten',
    inputType: 'percent',
    rules: { required: true, range: [0, 1, 'percent'] },
    suffix: '%',
  },
];

const fieldsAnschlussCosts = [
  {
    id: 'specific_district_network_cost',
    label: 'Kosten Verteilnetzleitung',
    inputType: 'float',
    rules: { required: true },
    suffix: '€/m',
  },
  {
    id: 'share_of_allowable_expenses_percent',
    label: 'Anteil absetzbarer Kosten',
    inputType: 'percent',
    rules: { required: true, range: [0, 1, 'percent'] },
    suffix: '%',
  },
  {
    id: 'sales_costs_euro_ha',
    label: 'Vertriebskosten pro Hausanschluss',
    inputType: 'float',
    rules: { required: true },
    suffix: '€/HA',
  },
  {
    id: 'building_pipe_costs_euro_m',
    label: 'Kosten Hausanschlussleitung',
    inputType: 'integer',
    rules: { required: true, range: [-2147483648, 2147483647] },
    suffix: '€/m',
  },
  // {
  //   id: 'building_pipe_underground_costs_euro_m',
  //   label: 'Tiefbaukosten Hausanschlussleitung',
  //   inputType: 'integer',
  //   rules: { required: true, range: [-2147483648, 2147483647] },
  //   suffix: '€/m',
  // },
];

export const inputGroups = [
  {
    title: 'Allgemein',
    inputs: fieldsAnschlussGeneral,
  },
  {
    title: 'Sensitivitäten',
    inputs: fieldsAnschlussSensitivity,
  },
  {
    title: 'Kosten',
    inputs: fieldsAnschlussCosts,
  },
  {
    title: 'Förderung & Investition',
    inputs: fieldsAnschlussInvest,
  },
];
