import municipalitySelectionInterface from './municipality-selection-interface';

export default class Scenario {
  constructor() {
    this.scenarioName = null;
    this.targetYear = null;
    this.baseYear = null;
    this.fullUsageHoursResidential = 2000;
    this.fullUsageHoursCommercial = 2400;
    this.fullUsageHoursIndustrial = 2400;
    this.fullUsageHoursPublic = 1700;
    this.fullUsageHoursOther = 1800;
    this.municipalities = new municipalitySelectionInterface();
  }

  get payload() {
    return {
      name: this.scenarioName,
      target_year: this.targetYear,
      base_year: this.baseYear,
      full_usage_hours_residential: this.fullUsageHoursResidential,
      full_usage_hours_commercial: this.fullUsageHoursCommercial,
      full_usage_hours_industrial: this.fullUsageHoursIndustrial,
      full_usage_hours_public: this.fullUsageHoursPublic,
      full_usage_hours_other: this.fullUsageHoursOther,
      municipalities_frames: this.formatMunicipalitesForPayload(
        this.municipalities.asList,
      ),
    };
  }

  get isBaseDataValid() {
    return (
      [
        this.scenarioName,
        this.baseYear,
        this.fullUsageHoursResidential,
        this.fullUsageHoursCommercial,
        this.fullUsageHoursIndustrial,
        this.fullUsageHoursPublic,
        this.fullUsageHoursOther,
        this.targetYear >= 2025 && this.targetYear <= 2050
          ? this.targetYear
          : null,
      ].findIndex((e) => e === null || e === '' || e === false) === -1
    );
  }

  get isMunicipalitiesValid() {
    return this.municipalities.hasAtLeastOneMunicipality();
  }

  get isMunicipalitiesFramesValid() {
    return this.municipalities.isValid();
  }

  get isValid() {
    return (
      this.isBaseDataValid &&
      [
        this.municipalities.asList.length > 0,
        this.isMunicipalitiesFramesValid,
      ].findIndex((e) => e === null || e === '' || e === false) === -1
    );
  }

  formatMunicipalitesForPayload(municipalities) {
    return municipalities.map((item) => {
      const newItem = { ...item };
      newItem.municipality_key = newItem.municipality_id;
      delete newItem.municipality_id;
      return newItem;
    });
  }
}
