import {
  LAYER_KEY__SCENARIO_NETWORK_CLUSTER,
  LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS,
  LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK,
} from '../layers/scenario';

export const SWITCH_ID__NETWORK_CLUSTER = 'LC_SZ_NETWORK_CLUSTER';
export const SWITCH_ID__BUILDING_NETWORK = 'LC_SZ_BUILDING_NETWORK';
export const SWITCH_ID__DISTRIBUTION_NETWORK = 'LC_SZ_DISTRIBUTION_NETWORK';

export default {
  title: 'Fernwärme',
  items: [
    {
      title: 'Verteilnetz',
      switchId: SWITCH_ID__DISTRIBUTION_NETWORK,
      itemClass: 'switch',
      onLayers: [LAYER_KEY__SCENARIO_DISTRIBUTION_NETWORK],
      turnOffSwitches: [
        'LC_POT_NETWORK_CLUSTER',
        'LC_POT_BUILDING_NETWORK',
        'LC_POT_DISTRIBUTION_NETWORK',
      ],
      active: false,
      radioGroups: [],
    },
    {
      title: 'Hausanschluss',
      switchId: SWITCH_ID__BUILDING_NETWORK,
      itemClass: 'switch',
      onLayers: [LAYER_KEY__SCENARIO_BUILDING_CONNECTIONS],
      turnOffSwitches: [
        'LC_POT_NETWORK_CLUSTER',
        'LC_POT_BUILDING_NETWORK',
        'LC_POT_DISTRIBUTION_NETWORK',
      ],
      active: false,
      radioGroups: [],
    },
    {
      title: 'Netzcluster',
      switchId: SWITCH_ID__NETWORK_CLUSTER,
      itemClass: 'switch',
      onLayers: [LAYER_KEY__SCENARIO_NETWORK_CLUSTER],
      turnOffSwitches: [
        'LC_POT_NETWORK_CLUSTER',
        'LC_POT_BUILDING_NETWORK',
        'LC_POT_DISTRIBUTION_NETWORK',
      ],
      active: false,
      radioGroups: [],
    },
  ],
};
