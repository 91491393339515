<template>
  <div class="flex items-center gap-2 mb-5">
    <div class="w-[250px] relative">
      <InputEl v-model="filterInput" placeholder="Nach Straße filtern" />
      <IconWrapper
        v-if="filterInput"
        icon="close"
        fill="text-title-color2"
        hover="hover:text-color2"
        class="absolute right-1.5 translate-y-[-50%] top-1/2 cursor-pointer"
        @click="resetFilter"
      />
    </div>
    <ButtonEl
      text="Daten abrufen"
      icon="youtube_searched_for"
      @click="getTableData"
    />
  </div>
  <VuetifyDataTable
    :headers="AssignmentsHeaders"
    :item-data="tableData"
    :total-items="resultsCount"
    item-value="id"
    :open-page-options-to-top="true"
    class="rounded-[4px] standard-elevation-1 table-anschlussadresse-custom overflow-hidden"
    @update:options="setOptions"
    @expanded="rowExpanded = $event[0]"
  >
    <template #streets_with_house_number="{ item }">
      <div class="flex">
        {{ item.streets_with_house_number.split(',')[0] }}
        <div
          v-if="item.streets_with_house_number.split(',').length > 1"
          class="text-color1 w-fit cursor-pointer ml-2"
        >
          weitere
          <VTooltip
            :model-value="tooltipActive === item.id"
            location="right center"
            activator="parent"
          >
            {{ item.streets_with_house_number.split(',').slice(1).join(',') }}
          </VTooltip>
        </div>
      </div>
    </template>
    <template #yearly="{ item }">
      <div class="flex content-center justify-end">
        <IconWrapper
          icon="edit_note"
          fill="text-title-neutral"
          hover="hover:text-neutral"
          class="cursor-pointer mr-2"
          @click="openDistrictDialog(item)"
        />
        <span>
          {{ showDistrictHeating(item.yearly[0].heat_demand_kwh) }} MWh/a</span
        >
      </div>
      <VuetifyDialog
        v-if="estateId === item.id && inputValues"
        v-model="districtHeatingDialog"
        title="Fernwärme-Verbrauch erfassen"
      >
        <template #content>
          <FormValidator v-model="isFormValid" class="mb-1">
            <div
              v-for="(year, index) in inputValues.yearly"
              :key="year.year"
              class="flex w-[768px] mb-1"
            >
              <DatePicker
                :placeholder="String(year.year)"
                :disabled="true"
                :label="index === 0 ? 'Ist-Jahr' : `Stützjahr ${index}`"
                class="mr-3 pt-1 w-[50%]"
              />

              <InputEl
                v-model="year.heat_demand_kwh"
                label="FW-Verbrauch"
                suffix="MWh/a"
                :rules="{ min: [0, 'float'] }"
                input-type="float"
                placeholder="Bitte eingeben"
                class="w-[50%]"
              />
            </div>
          </FormValidator>
        </template>
        <template #actions>
          <ButtonEl
            variant="secondary"
            text="Abbrechen"
            @click="districtHeatingDialog = false"
          />
          <ButtonEl
            text="Speichern"
            :disabled="!isFormValid"
            @click="saveYearly(item)"
          />
        </template>
      </VuetifyDialog>
    </template>
    <template #usable_area_m2="{ item }">
      {{
        item.usable_area_m2.toLocaleString('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }) + ' m²'
      }}
    </template>

    <template #start_at_year="{ item }">
      <div class="w-full h-full flex items-center justify-end">
        <span class="mr-2 cursor-pointer">{{
          getStartAtYear(item.id) || item.start_at_year
        }}</span>
        <div class="flex gap-1 items-center justify-end">
          <IconWrapper
            icon="edit"
            fill="text-title-color1"
            hover="hover:text-color1"
            class="pb-1 cursor-pointer"
            type="filled"
            @click="openStartYearDialog(item)"
          />
        </div>
      </div>
      <VuetifyDialog
        v-if="estateId === item.id && inputValues"
        v-model="startYearDialog"
        title="Anschlussjahr erfassen"
      >
        <template #content>
          <div class="h-20">
            <FormValidator v-model="isFormValid">
              <InputEl
                v-model="inputValues.start_at_year"
                input-type="integer"
                placeholder="Bitte Anschlussjahr eingeben"
                label="Anschlussjahr"
                :apply-thousands-separator="false"
                :rules="{ min: defaultStartYear, max: 2050 }"
              />
            </FormValidator>
          </div>
        </template>
        <template #actions>
          <ButtonEl
            variant="secondary"
            text="Abbrechen"
            @click="startYearDialog = false"
          />
          <ButtonEl
            text="Speichern"
            :disabled="!isFormValid"
            @click="saveStartYear(item, 'start_at_year')"
          />
        </template>
      </VuetifyDialog>
    </template>

    <template #expanded-row="data">
      <TabWireAnschlussAddressenExpanded
        :data
        :options
        @save-expanded-field="saveField($event.estate, $event.fieldName)"
      />
    </template>
  </VuetifyDataTable>

  <div v-if="isLoaded" class="p-[10px] bg-subtle mt-5 rounded-md">
    <ButtonEl
      text="Jetzt berechnen"
      icon="table_view"
      class="w-fit float-right"
      @click="emit('triggerCalculation')"
    />
  </div>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { onBeforeMount, ref, watch } from 'vue';
import { AssignmentsHeaders } from '../table-data';
import cookie from 'vue-cookies';
import { useStore } from 'vuex';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import DatePicker from '@/components/storybook/src/stories/datePicker/DatePicker.vue';
import cloneObject from '@/utils/objectUtils';
import TabWireAnschlussAddressenExpanded from '@/apps/economic-efficiency/edit-tab-items/TabWireAnschlussAddressenExpanded.vue';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => {},
  },
  options: {
    type: Object,
    default: () => {},
  },
  defaultStartYear: {
    type: Number,
    default: 2023,
  },
});

const emit = defineEmits(['patchSent', 'triggerCalculation']);

const store = useStore();

const tableData = ref([]);
const filterInput = ref();
const resultsCount = ref(null);
const tableOptions = ref({
  itemsPerPage: 10,
  page: 1,
});
const isLoaded = ref(false);
const rowExpanded = ref(false);
const rowSelected = ref(null);
const tooltipActive = ref(null);

const inputValues = ref(null);

const districtHeatingDialog = ref(false);
const startYearDialog = ref(false);
const estateId = ref(null);
const isFormValid = ref(true);

onBeforeMount(() => getTableData());

function getStartAtYear(id) {
  return tableData.value.find((i) => i.id === id).start_at_year;
}

function getTableData() {
  return axios({
    url: '/api/heatprojects/wi-re/estateconnections/',
    method: 'GET',
    params: {
      page: tableOptions.value.page,
      page_size: tableOptions.value.itemsPerPage,
      street: filterInput.value,
      wire_id: props.projectData.id,
      sortBy: tableOptions.value.sortBy?.[0]?.key,
      sortOrder: tableOptions.value.sortBy?.[0]?.order,
    },
  }).then((response) => {
    resultsCount.value = response.data.count;
    tableData.value = response.data.results.map((estate) => {
      const yearly = estate.yearly.map(transformIntoMWH);
      return { ...estate, yearly };
    });
    isLoaded.value = true;
  });
}

function transformIntoMWH(yearObject) {
  yearObject.heat_demand_kwh = yearObject.heat_demand_kwh / 1000;
  return yearObject;
}

function showDistrictHeating(rawNumber) {
  if (typeof rawNumber === 'number') {
    return rawNumber.toFixed(2).replace('.', ',');
  }
  return rawNumber;
}

function openDistrictDialog(estateObject) {
  districtHeatingDialog.value = true;
  openDialog(estateObject);
}

function openStartYearDialog(estateObject) {
  startYearDialog.value = true;
  openDialog(estateObject);
}

function openDialog(estateObject) {
  inputValues.value = cloneObject(estateObject);
  estateId.value = estateObject.id;
}

function setOptions(options) {
  rowSelected.value = null;
  tableOptions.value = options;
  getTableData();
}

function resetFilter() {
  filterInput.value = null;
  getTableData();
}

function saveStartYear(estate, fieldName) {
  startYearDialog.value = false;
  estate[fieldName] = inputValues.value[fieldName];
  inputValues.value = null;
  saveField(estate, fieldName);
}

function saveField(estate, fieldName) {
  const fieldValue = estate[fieldName];

  return axios({
    url: `/api/heatprojects/wi-re/estateconnections/${estate.id}/`,
    method: 'PATCH',
    headers: {
      'X-CSRFToken': cookie.get('csrftoken'),
    },
    params: {
      wire_id: props.projectData.id,
    },
    data: {
      [fieldName]: fieldValue,
    },
  })
    .then(() => {
      onSaveFieldSuccess(estate.id, fieldName, fieldValue);
    })
    .catch(() =>
      store.commit('layout/SHOWTOAST', {
        color: 'rgb(var(--spot-error))',
        message: 'Es ist ein Fehler aufgetreten',
        timeout: 2000,
      }),
    );
}

function onSaveFieldSuccess(id, fieldName, fieldValue) {
  rowSelected.value = null;
  tableData.value = tableData.value.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        [fieldName]:
          fieldName === 'yearly'
            ? fieldValue.map(transformIntoMWH)
            : fieldValue,
      };
    }
    return item;
  });
  emit('patchSent');
  store.commit('layout/SHOWTOAST', {
    color: 'rgb(var(--spot-success))',
    message: 'Erfolgreich gespeichert!',
    timeout: 2000,
  });
}

function saveYearly(item) {
  districtHeatingDialog.value = false;
  const yearlyTransformedToKwh = inputValues.value.yearly.map((yearObject) => {
    const HeatDemandKwh = yearObject.heat_demand_kwh * 1000;
    return { ...yearObject, heat_demand_kwh: HeatDemandKwh };
  });

  inputValues.value = null;
  saveField({ ...item, yearly: yearlyTransformedToKwh }, 'yearly');
}

watch(
  () => rowExpanded.value,
  (value) => {
    if (value) {
      rowSelected.value = null;
    }
  },
);
</script>

<style lang="scss">
.table-anschlussadresse-custom {
  thead > tr {
    height: 60px !important;

    .v-data-table-header__content {
      height: 100%;
      display: flex;
      align-items: flex-start;
    }

    th {
      padding-top: 4px !important;
    }

    th:not(:first-child):not(:nth-child(2)) {
      .v-data-table-header__content span {
        white-space: break-spaces;
      }
    }
  }
}
</style>
