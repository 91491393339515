import buildings from './buildings';
import aggregation from './aggregation';
import inventoryNetwork from './inventory-network';
import baseData from './baseData';
import potentials from './potentials';
import scenario from './scenario';
import heatProject from './heatProject';
import { infraModules } from '@/configs/infra-modules';

export const layerPanelConfig = {
  sections: [
    {
      title: 'Basisdaten Bestand und Zukunft',
      groups: [buildings, aggregation, inventoryNetwork, baseData],
      show: true,
    },
    {
      title: 'Potenzialanalyse',
      groups: potentials,
      show: true,
    },
    {
      title: 'Zielszenarien',
      groups: [scenario],
      module: infraModules.scenarios,
      show: true,
    },
    {
      title: 'Projektgebiete',
      id: 'heatProject',
      groups: [heatProject],
      show: false,
    },
  ],
};
