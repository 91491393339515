export const scenarioTabs = [
  {
    icon: 'text_snippet',
    label: 'Basisdaten',
    testId: 'base-data-tab',
  },
  {
    icon: 'pin_drop',
    label: 'Gebietsauswahl',
    testId: 'municpality-selection-tab',
  },
  {
    icon: 'pin_drop',
    label: 'Szenariorahmen',
    testId: 'scenario-frame-tab',
  },
  {
    icon: 'view_timeline',
    label: 'Effizienzmaßnahmen',
    testId: 'efficiency-measures-tab',
  },
  {
    icon: 'heat_pump',
    label: 'Technologien',
    testId: 'technologies-tab',
  },
  {
    icon: 'data_thresholding',
    label: 'Optionale Parameter',
    testId: 'optional-parameter-tab',
  },
];

export const tableHeaders = {
  scenarioFrame: [
    {
      title: 'Szenariorahmen',
      key: 'scenario_frame_name',
      width: '150px',
      align: 'left',
    },
  ],
  efficiency: [
    {
      title: 'Stadtteil',
      key: 'district_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Sanierungstiefe',
      key: 'refurbishmentValue',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Sanierungsquote',
      key: 'refurbishmentRate',
      width: '150px',
      align: 'left',
    },
  ],
  priorityArea: [
    {
      title: 'Stadtteil',
      key: 'district_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Typ Vorranggebiet',
      key: 'priorityArea',
      width: '150px',
      align: 'left',
    },
  ],
  expansionLimit: [
    {
      title: 'Stadtteil',
      key: 'district_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Wärmenetzgebiet',
      key: 'heatingNetwork',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Wasserstoffnetzgebiet',
      key: 'hydrogenNetwork',
      width: '150px',
      align: 'left',
    },
  ],
};

export const fullUsageHoursFields = [
  {
    key: 'fullUsageHoursResidential',
    label: 'Vollbenutzungsstunden Wohnen',
    dataTestId: 'scenario-hours-residential-input',
  },
  {
    key: 'fullUsageHoursCommercial',
    label: 'Vollbenutzungsstunden Gewerbe',
    dataTestId: 'scenario-hours-commercial-input',
  },
  {
    key: 'fullUsageHoursIndustrial',
    label: 'Vollbenutzungsstunden Industrie',
    dataTestId: 'scenario-hours-industrial-input',
  },
  {
    key: 'fullUsageHoursPublic',
    label: 'Vollbenutzungsstunden Öffentlich',
    dataTestId: 'scenario-hours-public-input',
  },
  {
    key: 'fullUsageHoursOther',
    label: 'Vollbenutzungsstunden Sonstige',
    dataTestId: 'scenario-hours-other-input',
  },
];
